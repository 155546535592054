// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

.wp-block-buttons {
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','md');
  .wp-block-button {
    a {
      @extend .btn;
    }
  }
}

// Media alignment
.wp-block-image {
  figure {
    &.aligncenter {
      text-align: center;
    }
    figcaption {
      padding: 2px 0;
      font-size: $font-size-sm;
    }
  }
}

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}


.wp-block-embed.is-type-video {
  .wp-block-embed__wrapper {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
/*
.wp-block-embed {
  .wp-block-embed__wrapper {
    iframe {
      width: 100%;
    }
  }
}
*/

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
