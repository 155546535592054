header.banner {
  @include color-block('black');
  position: fixed;
  top: 0;
  left: 0;
  width: $header-width-sm;
  text-align: center;
  z-index: 10;
  @include media-breakpoint-up(lg) {
    @include color-block('gray');
    @include full-padding-spacer(sm);
    overflow: scroll;
    width: $header-width;
    height: $header-height;
    left: auto;
    right: 0;
  }
  .mobile-wrap {
    .brand {
      display: block;
      img {
        height: 28px;
        width: auto;
      }
    }
    .row {
      min-height: $header-height-sm;
      align-items: center;
      &>.col:first-child {
        .brand {
          text-align: left;
          img {
            height: 32px;
          }
        }
      }
      &>.col:last-child {
        text-align: right;
      }
    }
    .navbar-toggler {
      position: relative;
      border: none;
      background-color: transparent;
      height: $grid-gutter-width*1.5;
      color: $white;
      i {
        font-size: $h2-font-size;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        &.fa-times {
          @include transition($link-transition-bars);
    			transform: rotate(-180deg) scale(.5);
    			opacity: 0;
    			left: 2px;
        }
        &.fa-bars {
          @include transition($link-transition-bars);
        }
      }
      .primary-nav-engaging & {
        i {
          &.fa-times {
            transform: rotate(0deg) scale(1);
      			opacity: 1;
          }
          &.fa-bars {
            transform: rotate(180deg) scale(.5);
      			opacity: 0;
          }
        }
      }
    }
  }
  .banner-wrap {
    @include media-breakpoint-up(lg) {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    .brand {
      display: none;
      @include media-breakpoint-up(lg) {
        @include scaled-spacer('margin','top','sm');
        @include scaled-spacer('margin','bottom','sm');
        display: block;
        max-width: 133px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .nav-primary {
    @include scaled-spacer('margin','top','lg');
    @include scaled-spacer('margin','bottom','lg');
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('margin','top','sm');
      @include scaled-spacer('margin','bottom','sm');
    }
    ul {
      @include list-normalize;
      li {
        @include scaled-spacer('margin','top','md');
        @include scaled-spacer('margin','bottom','md');
        @include media-breakpoint-up(lg) {
          @include scaled-spacer('margin','top','xs-sm');
          @include scaled-spacer('margin','bottom','xs-sm');
        }
        a {
          @include headline;
          @include resize-header(h2);
          text-decoration: none;
          &[href*='angel-city-brewery.square']:after {
            font-family: 'Font Awesome 5 Duotone';
            font-weight: $font-weight-heavy;
            content: " \f35d";
            @include resize-header(h4);
            margin-left: -.25em;
          }
          @include hover-focus {
            color: $brand-color;
          }
        }
        &.active {
          a {
            color: $brand-color;
          }
        }
      }
    }
  }
  .hours-social {
    @include scaled-spacer('padding','bottom','sm');
    .hours-info {
      @include last-child-zero-margin;
      font-size: $font-size-xs;
      h2 {
        @include resize-header(h5);
        @include scaled-spacer('margin','bottom','xs');
      }
    }
    .nav-social {
      @include scaled-spacer('margin','top','sm-md');
      ul {
        @include list-normalize;
        justify-content: center;
        li {

          a {
            padding: 0 .75em;
          }
//           display: inline-block;
        }
      }
    }
  }
}
body.primary-nav-engaged {
  @include media-breakpoint-down(md) {
    overflow: hidden;
    header.banner {
      height: 100%;
      overflow: hidden;
      #banner-wrap {
        height: calc(100% - #{$header-height-sm});
        overflow: scroll;
      }
    }
  }
}
