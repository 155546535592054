/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $headline, $link, $hover, $border, $input, $placeholder, $inputtext, $btnbg, $btntext) {
  $map: (
    bg:           $bg,
    text:         $text,
    headline:     $headline,
    link:         $link,
    hover:        $hover,
    border:       $border,
    input:        $input,
    placeholder:  $placeholder,
    inputtext:    $inputtext,
    btnbg:        $btnbg,
    btntext:      $btntext,
  );
  @return $map;
}


/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(base)*gutter-scale($scale);
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(base)*gutter-scale($scale)) !important;
}
@mixin scaled-absolute($csslocation,$scale) {
  #{$csslocation}: (gutter-height(base)*gutter-scale($scale));
}
@mixin scaled-absolute-negative($csslocation,$scale) {
  #{$csslocation}: -(gutter-height(base)*gutter-scale($scale));
}

@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}

/* COLORS
  --------------------------------------- */

/*1 name        2 bgcolor     3 text      4 headline    5 link color    6 hover color      7 border   8 input     9 placeholder   10 inputtext 11 btnbg  12 btntext */
$color-list: (
  black         $black        $white      $white        $white          $link-hover-color  $white     $gray-dark  $gray-md        $white      $white    $black,
  white         $body-bg      $body-color $body-color   $link-color     $link-hover-color  $black     $gray       $gray-md        $black      $black    $white,
  gray          $gray         $body-color $body-color   $link-color     $link-hover-color  $black     $white      $gray-md        $black      $black    $white,
  teal          $teal         $white      $white        $white          $black             $white     $white      $gray-md        $black      $black    $white,
);

$color-map: ();

@each $value in $color-list {
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    border-color: #{nth($value, 7)};
    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6,.overline {
      color: #{nth($value, 4)};
    }
    a {
      color: #{nth($value, 5)};
      @include hover-focus {
        color: #{nth($value, 6)};
      }
    }
    .form-control {
      background-color: #{nth($value, 8)};
      border-color: #{nth($value, 2)};
      color: #{nth($value, 10)};
      &::-webkit-input-placeholder {
    	  color: #{nth($value, 9)};
    	}
    	&:-moz-placeholder { /* Firefox 18- */
    	  color: #{nth($value, 9)};
    	}
    	&::-moz-placeholder {  /* Firefox 19+ */
    	  color: #{nth($value, 9)};
    	}
    	&:-ms-input-placeholder {
    	  color: #{nth($value, 9)};
    	}
    	&:focus {
    		&::-webkit-input-placeholder {
    		  color: transparent;
    		}
    		&:-moz-placeholder { /* Firefox 18- */
    		  color: transparent;
    		}
    		&::-moz-placeholder {  /* Firefox 19+ */
    		  color: transparent;
    		}
    		&:-ms-input-placeholder {
    		  color: transparent;
    		}
      }
    }
    .btn {
      background-color: #{nth($value, 11)};
      border-color: #{nth($value, 11)};
      color: #{nth($value, 12)};
      @include hover-focus {
        background-color: #{nth($value, 12)};
        border-color: #{nth($value, 11)};
        color: #{nth($value, 11)};
      }
    }
    a.beer-link {
      color: #{nth($value, 5)};
      h3 {
        @include transition($link-transition);
        color: #{nth($value, 5)};
      }
      @include hover-focus {
        color: #{nth($value, 6)};
        h3 {
          color: #{nth($value, 6)};
        }
      }
    }
    &.event-link {
      article {
        h3,p.full-details {
          @include transition($link-transition);
          color: #{nth($value, 4)};
        }
      }
      @include hover-focus {
        article {
          h3,p.full-details {
            color: #{nth($value, 6)};
          }
        }
      }
    }
    .nav-primary {
      ul {
        li {
          &.active {
            a {
              color: #{nth($value, 6)};
            }
          }
        }
      }
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)},#{nth($value, 9)},#{nth($value, 10)},#{nth($value, 11)},#{nth($value, 12)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  border-color: map-deep-get($color-map, $block-color, "border");
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6,.overline {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  a {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
  }
  .form-control {
    background-color: map-deep-get($color-map, $block-color, "input");
    border-color: map-deep-get($color-map, $block-color, "input");
    color: map-deep-get($color-map, $block-color, "inputtext");
    &::-webkit-input-placeholder {
  	  color: map-deep-get($color-map, $block-color, "placeholder");
  	}
  	&:-moz-placeholder { /* Firefox 18- */
  	  color: map-deep-get($color-map, $block-color, "placeholder");
  	}
  	&::-moz-placeholder {  /* Firefox 19+ */
  	  color: map-deep-get($color-map, $block-color, "placeholder");
  	}
  	&:-ms-input-placeholder {
  	  color: map-deep-get($color-map, $block-color, "placeholder");
  	}
  	&:focus {
  		&::-webkit-input-placeholder {
  		  color: transparent;
  		}
  		&:-moz-placeholder { /* Firefox 18- */
  		  color: transparent;
  		}
  		&::-moz-placeholder {  /* Firefox 19+ */
  		  color: transparent;
  		}
  		&:-ms-input-placeholder {
  		  color: transparent;
  		}
    }
  }
  .btn {
    background-color: map-deep-get($color-map, $block-color, "btnbg");
    border-color: map-deep-get($color-map, $block-color, "btnbg");
    color: map-deep-get($color-map, $block-color, "btntext");
    @include hover-focus {
      background-color: map-deep-get($color-map, $block-color, "btntext");
      border-color: map-deep-get($color-map, $block-color, "btnbg");
      color: map-deep-get($color-map, $block-color, "btnbg");
    }
  }
  a.beer-link {
    color: map-deep-get($color-map, $block-color, "link");
    h3 {
      @include transition($link-transition);
      color: map-deep-get($color-map, $block-color, "link");
    }
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
      h3 {
        color: map-deep-get($color-map, $block-color, "hover");
      }
    }
  }
  &.event-link {
    article {
      h3,p.full-details {
        @include transition($link-transition);
        color: map-deep-get($color-map, $block-color, "headline");
      }
    }
    @include hover-focus {
      article {
        h3,p.full-details {
          color: map-deep-get($color-map, $block-color, "hover");
        }
      }
    }
  }
  .nav-primary {
    ul {
      li {
        &.active {
          a {
            color: map-deep-get($color-map, $block-color, "hover");
          }
        }
      }
    }
  }
}


/* FONTS
  --------------------------------------- */
@mixin overline {
  font: $font-weight-bold $font-size-sm $font-family-sans;
  text-transform: uppercase;
  line-height: $headings-line-height;
  letter-spacing: .1em;
}
@mixin xsline {
  @include overline;
  font-size: $font-size-xs;
}

@mixin tinyline {
  @include overline;
  font-size: $font-size-tiny;
  line-height: 1.5;
  letter-spacing: .2em;
}
@mixin headline {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  text-transform: uppercase;
  letter-spacing: .07em !important;
}
@mixin sans-serif {
  font-family: $font-family-sans;
  text-transform: none;
  letter-spacing: normal !important;
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size,
  hero: $hero-font-size,
  h1-xl: $h1-xl-font-size
);
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}


/* ELEMENTS
  --------------------------------------- */
@mixin list-normalize {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin animate-arrow {
  &:after {
    transform: translateX($arrow-animate-size);
  }
}
@mixin paragraph-spacing {
  margin-bottom: 1.5rem;
}
@mixin select-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-image: url(../images/caret-down-solid.svg);
  background-size: auto 1.5em;
  padding-right: 2em !important;
}
@mixin tile-padding {
  @include last-child-zero-margin;
  @include scaled-spacer('padding','top','md');
  @include scaled-spacer('padding','bottom','md');
  @include scaled-spacer('padding','left','sm');
  @include scaled-spacer('padding','right','sm');
  @include media-breakpoint-up(md) {
    @include full-padding-spacer(lg);
  }
  @include media-breakpoint-up(lg) {
    @include full-padding-spacer(xl);
  }
}
@mixin tile-img-faded($color) {
  figure {
    @include color-block($color);
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: .6;
    }
  }
}

@mixin tile-img-hover($color) {
  figure {
    @include color-block($color);
    overflow: hidden;
    img {
      transition: all ease-in .1s;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  @include hover-focus {
    figure {
      img {
        transform: scale(1.0125);
        opacity: .98;
      }
    }
  }
}
@mixin tile-img-hover-trans {
  figure {
    img {
      opacity: .6;
    }
  }
  @include hover-focus {
    figure {
      img {
        opacity: .95;
      }
    }
  }
}
@mixin single-content-setup {
  @include last-child-zero-margin;
  flex-direction: column;
  .entry-title {
    order: 2;
    z-index: 1;
    @include color-block('black');
    @include scaled-spacer-negative('margin','left','sm');
    @include scaled-spacer-negative('margin','right','sm');
    @include full-padding-spacer(sm);
    @include media-breakpoint-down(xs) {
      margin-top: 0 !important;
    }
    @include media-breakpoint-up(sm) {
      @include scaled-spacer-negative('margin','left','md');
      @include scaled-spacer-negative('margin','right','md');
      @include scaled-spacer('padding','left','md');
      @include scaled-spacer('padding','right','md');
    }
    @include media-breakpoint-up(md) {
      @include scaled-spacer-negative('margin','left','lg');
      @include scaled-spacer-negative('margin','right','lg');
      @include scaled-spacer('padding','left','lg');
      @include scaled-spacer('padding','right','lg');
      @include scaled-spacer('padding','top','sm-md');
      @include scaled-spacer('padding','bottom','sm-md');
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer-negative('margin','left','xl');
      @include scaled-spacer-negative('margin','right','xl');
      @include scaled-spacer('padding','left','xl');
      @include scaled-spacer('padding','right','xl');
    }
    @include media-breakpoint-up(xl) {
      @include scaled-spacer-negative('margin','left','xxxl');
      @include scaled-spacer-negative('margin','right','xxxl');
      @include scaled-spacer('padding','left','xxxl');
      @include scaled-spacer('padding','right','xxxl');
      @include scaled-spacer('padding','top','md');
      @include scaled-spacer('padding','bottom','md');
    }
  }
  figure.entry-image {
    order: 1;
    @include wp-full-width-no-padding;
    @include scaled-spacer-negative('margin','top','lg');
    @include scaled-spacer('padding','left','sm');
    @include scaled-spacer('padding','right','sm');
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','left','md');
      @include scaled-spacer('padding','right','md');
    }
    margin-bottom: 0;
    height: 340px;
    align-items: center;
    @include media-breakpoint-up(md) {
      height: 420px;
    }
    @include media-breakpoint-up(lg) {
      height: 490px;
    }
    @include media-breakpoint-up(xl) {
      height: 560px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  div.entry-content {
    order: 3;
  }
  footer {
    @include last-child-zero-margin;
    @include scaled-spacer('margin','top','lg');
    order: 4;
    ul.categories {
      @include list-normalize;
      @include scaled-spacer('margin','top','sm');
      li {
        display: inline-block;
        @include scaled-spacer('margin','top','xs');
        @include scaled-spacer('margin','right','sm');
      }
    }
  }
}





/* NAVS
  --------------------------------------- */
@mixin nav-horizontal {
  position: relative;
  overflow: hidden;
  ul {
    @include list-normalize;
    @extend .d-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    li {
      flex-basis: auto;
      margin: 0;
      padding: 0 1em;
      text-align: center;
      line-height: 1;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
@mixin nav-piped {
  @include nav-horizontal;
  ul {
    margin-left: -1px;
    li {
      border-left-width: 1px;
      border-left-style: solid;
      line-height: 1;
      &:first-child {
        border-left: none;
      }
    }
  }
}

/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  margin: auto calc(50% - 50vw);
  transform: translateX(calc(50vw - 50%));
  width: 100vw;
  @include media-breakpoint-up(lg) {
    width: calc(100vw - #{$header-width});
  }
/*
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
*/
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}

@mixin main-wide-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin hero-column-width {
  @include make-col-ready();
  @include make-col(10);
  @include make-col-offset(1);
  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}
@mixin bordered-content {
  @include full-padding-spacer(sm);
  @include scaled-spacer('margin','top','lg');
  @include scaled-spacer('margin','bottom','lg');
  @include media-breakpoint-up(lg) {
    @include scaled-spacer('padding','left','md');
    @include scaled-spacer('padding','right','md');
  }
}
