a.btn,.btn {
  @include transition($link-transition);
  @include headline;
  line-height: $btn-line-height;
  cursor: inherit;
  &.btn-lg {
    padding: $btn-padding-y-lg $btn-padding-x-lg;
    font-size: $btn-font-size;
  }
  &.btn-white {
    background-color: $white;
  }
}
