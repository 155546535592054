// Grid system
body:not(.home):not(.blog):not(.category):not(.post-type-archive-tribe_events) {
  main.main,main#tribe-events,main#tribe-events-pg-template {
    @include main-column-width;
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','lg');
    @include last-child-zero-margin;
  }
}
body {
  &.blog,&.category,&.post-type-archive-tribe_events {
    .main {
      width: 100%;
    }
  }
  &.home {
    main.main {
      @include main-wide-column-width;
      @include scaled-spacer('padding','top','lg');
      @include scaled-spacer('padding','bottom','lg');
      @include last-child-zero-margin;
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
