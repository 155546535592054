.wp-full-width {
  @include wp-full-width-no-padding;
}
.acb-block {
  @include color-block('white');
  @include bordered-content;
  &+.acb-block:not(.xola-block) {
    @include scaled-spacer-negative('margin','top','lg');
    padding-top: 0;
    &.event-grid {
      @include scaled-spacer('padding','top','sm');
      &+nav.posts-navigation {
        @include scaled-spacer-negative('margin','top','sm');
      }
    }
  }
}
.main,#tribe-events {
  &>:first-child {
    &.acb-block {
      @include scaled-spacer-negative('margin','top','lg');
      padding-top: 0;
      .post-type-archive-tribe_events & {
        margin-top: 0 !important;
      }
    }
  }
  &>:last-child {
    &.acb-block {
      @include scaled-spacer-negative('margin','bottom','lg');
      padding-bottom: 0;
    }
  }
}
.banner-block {
  article {
    @include color-block('black');
    width: 100%;
    &>.container-fluid {
      &>.row {
        align-items: center;
      }
    }

    .media,.details {
      @include make-col-ready();
      @include make-col(12);
      height: 100%;
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
    .media {
      min-height: 240px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(sm) {
        min-height: 320px;
      }
      @include media-breakpoint-up(md) {
        min-height: 360px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 440px;
      }
      @include media-breakpoint-up(xl) {
        min-height: 480px;
      }
    }
    .details {
      @include tile-padding;
      h2 {
        @include scaled-spacer('margin','bottom','sm');
      }
      p {
        @include scaled-spacer('margin','bottom','sm');
      }

    }
  }
  &.banner-block-tiled {
    @include media-breakpoint-up(md) {
      &:nth-child(odd) {
        article {
          &>.container-fluid {
            &>.row {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
  &.banner-block-fullwidth {
    .banner-wrap {
      @include color-block('black');
      min-height: 480px;
      position: relative;
      align-items: center;
      width: 100%;
      &:before {
        content: "";
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.5;
      }
    }
    article {
      width: 100%;
      .details {
        @include hero-column-width;
        @include scaled-spacer('padding','top','lg');
        @include scaled-spacer('padding','bottom','lg');
        text-align: center;
      }
    }
  }

}
.beer-group-block {
  @include scaled-spacer('margin','bottom','lg');
  @include scaled-spacer('padding','bottom','md');
  @include color-block('white');
  &>.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .beer-group-title {
    h2 {
      @include scaled-spacer('margin','bottom','sm');
      text-align: center;
    }
  }
  .beer-grid {
    @include make-row();
    justify-content: center;
    a.beer {
      @include tile-img-hover('gray');
      @include scaled-spacer('margin','bottom','sm');
      @include make-col-ready();
      @include make-col(6);
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }
      text-decoration: none;
      figure {
        margin: 0;
      }
      .details {
        @include full-padding-spacer(xs-sm);
        font-size: $font-size-sm;
        text-align: center;
        h3 {
          @include scaled-spacer('margin','bottom','xs');
        }
        .beer-stats {
          justify-content: center;
        }
      }
    }
    &.smaller-grid {
      a.beer {
        @include make-col(6);
        @include media-breakpoint-up(sm) {
          @include make-col(4);
        }
        @include media-breakpoint-up(md) {
          @include make-col(3);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(3);
        }
        @include media-breakpoint-up(xl) {
          @include make-col(2);
        }
        .details {
          @include full-padding-spacer(sm);
          @include color-block('gray');
          @include transition($link-transition);
          @extend .d-flex;
          flex-direction: column;
          h3 {
            @include scaled-spacer('margin','top','xs-sm');
            @include scaled-spacer('margin','bottom','sm-md');
            @include transition($link-transition);
            @extend .d-flex;
            justify-content: center;
            align-items: center;
          }
          .beer-stats {
            flex-direction: column;
            justify-content: flex-end;
            span {
              @include xsline;
              display: block;
              line-height: 1;
              &+span {
                margin-top: 4px;
              }
            }
          }
        }
        @include hover-focus {
          .details {
            @include color-block('black');
          }
        }
      }
    }
  }
}
.beer-ontap-block {
  @include scaled-spacer('margin','bottom','lg');
  text-align: center;
  h2 {
    @include resize-header(h1);
  }
  ul.beers {
    @include list-normalize;
    @include scaled-spacer('margin','bottom','md');
    li {
      a {
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        border-bottom: 1px dashed $black;
        text-decoration: none;
        h3 {
          margin: 0;
          text-align: left
        }
        div.beer-stats {
          @include xsline;
          text-align: right;
          span {
//             white-space: nowrap;
            &+span {
              min-width: 5.4em;
              @include media-breakpoint-up(md) {
                @include scaled-spacer('margin','left','xs');
              }
            }
          }
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            span {
              margin-top: 2px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
.gallery-block {
  &>.container-fluid {
    padding-left: 0;
    padding-right: 0;
    &>.row {
      justify-content: center;
    }
  }
  figure {
    @include scaled-spacer('margin','bottom','sm');
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    text-align: center;
  }

}
.press-releases-block {
  @include scaled-spacer('margin','top','xl');
  @include scaled-spacer('margin','bottom','xl');
  ul.press-releases {
    @include list-normalize;
    @include scaled-spacer('margin','bottom','md');
    li {
      @include scaled-spacer('margin','top','xs');
      a {
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        text-decoration: none;
        time {
          @include overline;
          font-size: $font-size-base;
          display: block;
          margin: .5em 0 0;
        }
      }
    }
  }
}

.contact-columns-block {
  .main-form {
    @include tile-padding;
    @include color-block('gray');
  }
  .form-list {
    @include tile-padding;
    @include color-block('black');
    ul {
      @include list-normalize;
      li {
        @include scaled-spacer('margin','bottom','sm');
      }
    }
  }
}
.beer-columns-block {
  .beer-content {
    @include tile-padding;
    @include color-block('gray');
  }
  .beer-stats {
    @include tile-padding;
    @include color-block('black');
    .stats1 {
      &>div:last-child {
        text-align: right;
      }
    }
    .overline {
      @include scaled-spacer('margin','bottom','sm');
    }
    span {
      @include headline;
      @include resize-header(h3);
      display: block;
      &.availability_form {
        @include overline;
        @include scaled-spacer('margin','top','sm');
        margin-bottom: 0;
        font-size: $font-size-base;
      }
    }
    .availability {
      @include scaled-spacer('margin','top','lg');
    }
  }
}
.xola-block {
  .xola-col {
    @include scaled-spacer('margin','top','md');
    text-align: center;
  }
}
