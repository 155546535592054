.article-grid {
  @include make-row();
  a.article-item {
    @include tile-img-hover('black');
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include scaled-spacer('margin','bottom','md');
    text-align: center;
    font-weight: $font-weight-bold;
    text-decoration: none;
    line-height: 1.2;
    figure {
      @include scaled-spacer('margin','bottom','sm');
    }
  }
}
.single-post {
  article.hentry {
    @include single-content-setup;
  }
}
section.related-posts {
  @include color-block('white');
  @include scaled-spacer('margin','top','md');
  h2 {
    text-align: center;
  }
}
