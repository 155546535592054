.content-info {
  @include color-block('black');
  @include last-child-zero-margin;
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  @include scaled-spacer('padding','left','sm');
  @include scaled-spacer('padding','right','sm');
  text-align: center;
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','left','md');
    @include scaled-spacer('padding','right','md');
  }
  .details {
    @include main-column-width;
  }
  .nav-footer,form.email-signup,.adr,.copyright,.nav-legal {
    @include scaled-spacer('margin','top','md');
    @include last-child-zero-margin;
  }
  .adr {
    a {
      text-decoration: none;
    }
  }
  .brand {
    @include last-child-zero-margin;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: $grid-gutter-width*4;
  }
  form.email-signup {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    .small-label {
      color: $gray;
    }
    input[type=email] {
      border: 1px solid $white;
    }
  }
  .nav-footer {
    ul {
      @include list-normalize;
      li {
        @include scaled-spacer('margin','top','xs');
        @include scaled-spacer('margin','bottom','xs');
        a {
          @include overline;
          letter-spacing: .2em;
          font-size: $font-size-lg;
          text-decoration: none;
        }
      }
    }
  }
  .copyright {
    @include tinyline;
  }
  .nav-legal {
    @include tinyline;
    @include nav-piped;
    ul {
      li {
        a {
          text-decoration: none;
        }
      }
    }
  }
}
