// Grid settings
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 992px,
  xl: 1152px
);

$main-sm-columns:         12;
$sidebar-sm-columns:      4;

$grid-gutter-width-base:  16px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      $grid-gutter-width;

$base-gutter-heights: (
  base: $grid-gutter-height
);
$base-gutter-scales: (
  xs: .5,     // 8
  xs-sm: .75, // 12
  sm: 1,      // 16
  sm-md: 1.5, // 24
  md: 2,      // 32
  lg: 3,      // 48
  xl: 4,      // 64
  xxl: 5,     // 80
  xxxl: 6     // 96
);

$header-height:         100%;
$header-height-sm:      64px;
$header-width:          240px;
$header-width-sm:       100%;

$border-radius:         0;
$border-radius-event:   4px;

/* COLORS
  --------------------------------------- */

$teal:                  #60A6A5;
$teal-dark:             #427675;
$white:                 #fff;
$black:                 #111;
$gray:                  #f1f1f1;
$gray-dark:             #333;
$gray-md:               #888;

$body-color:            $black;
$body-bg:               $white;

$brand-primary:         $black;
$brand-secondary:       $white;
$brand-color:           $teal;

$link-color:            $brand-primary;
$link-decoration:       underline;
$link-hover-color:      $teal-dark;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;
$link-transition-bars:  opacity .2s, transform .2s;


/* FONTS
  --------------------------------------- */
$font-family-sans:      'neuzeit-grotesk', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-title:     'refrigerator-deluxe', Impact, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Arial Black", sans-serif;
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;       //  16
$font-size-tiny:        .625rem;    //  10
$font-size-xs:          .75rem;     //  12
$font-size-sm:          .875rem;    //  14
$font-size-lg:          1.2rem;     //  20
$font-size-xl:          1.5rem;     //  24

$hero-font-size:        3.5rem;     //  56
$h1-xl-font-size:       2.5rem;     //  40

$h1-font-size:          2.25rem;    //  36
$h2-font-size:          1.728rem;   //  27
$h3-font-size:          1.5rem;     //  24
$h4-font-size:          1.2rem;     //  20
$h5-font-size:          1rem;       //  16
$h6-font-size:          .875rem;    //  14

$line-height-base:      1.3;

$font-weight-normal:    400;
$font-weight-base:      $font-weight-normal;
$font-weight-bold:      700;
$font-weight-heavy:     900;

$headings-font-family:  $font-family-title;
$headings-font-weight:  $font-weight-heavy;
$headings-line-height:  1;


// Buttons
$btn-padding-y:               $grid-gutter-width/2;
$btn-padding-x:               $grid-gutter-width;
$btn-font-family:             $headings-font-family;
$btn-font-size:               $font-size-base;
$btn-line-height:             1;
$btn-white-space:             nowrap;

$btn-padding-y-lg:            $grid-gutter-width;
$btn-padding-x-lg:            $grid-gutter-width*1.25;

$btn-border-width:            2px;

$btn-font-weight:             $font-weight-heavy;
$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-block-spacing-y:         .5rem;

$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius;
$btn-border-radius-sm:        $border-radius;


// Forms
$input-padding-y:                       $grid-gutter-width;
$input-padding-x:                       $input-padding-y;
$input-font-size:                       $font-size-base;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     1;

$input-bg:                              $white;
$input-bg-invert:                       $gray-dark;

$input-color:                           $black;
$input-color-invert:                    $white;
$input-border-color:                    $input-bg;
$input-border-color-invert:             $input-bg-invert;
$input-border-width:                    2px;
$input-box-shadow:                      none;

$input-border-radius:                   $border-radius;
$input-border-radius-lg:                $border-radius;
$input-border-radius-sm:                $border-radius;

$input-focus-bg:                        $input-bg;
$input-focus-bg-invert:                 $input-bg-invert;
$input-focus-border-color:              $gray-dark;
$input-focus-border-color-invert:       $gray;
$input-focus-color:                     $input-color;
$input-focus-color-invert:              $input-color-invert;

$input-placeholder-color:               rgba($input-color,.4);
$input-placeholder-color-invert:        rgba($input-color-invert,.4);

$custom-select-feedback-icon-padding-right: 0;
$custom-select-feedback-icon-position:      0;
$custom-select-feedback-icon-size:          0;
$custom-select-padding-x:                   $grid-gutter-width;
$custom-select-padding-y:                   $grid-gutter-width;
$custom-select-indicator-padding:           $grid-gutter-width;


