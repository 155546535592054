.event-grid {
  @include scaled-spacer('margin','bottom','sm');
  .event-item {
    @include scaled-spacer('margin','bottom','sm');
    article {
      @include last-child-zero-margin;
      @include color-block('gray');
      @include tile-img-faded('black');
      height: 100%;
      border-radius: $border-radius-event;
      font-size: $font-size-sm;
      a {
        text-decoration: none;
      }
      p {
        @include scaled-spacer('margin','bottom','sm');
      }
      time {
        display: block;
      }
      figure {
        position: relative;
        border-top-left-radius: $border-radius-event;
        border-top-right-radius: $border-radius-event;
        margin: 0;
        img {
          overflow: hidden;
        }
        time.startdate {
          @include headline;
          @include resize-header('h3');
          position: absolute;
          top: $grid-gutter-width*.75;
          left: $grid-gutter-width;
        }
      }
      .event-details {
        @include full-padding-spacer('sm');
        @include last-child-zero-margin;
        h3 {
          @include sans-serif;
          font-weight: $font-weight-bold;
        }
        .time-location {
          @include scaled-spacer('margin','bottom','sm');
          address {
            display: block;
            font-weight: $font-weight-bold;
          }
        }
        .full-details {
          text-decoration: underline;
        }
      }
    }
    .event-link {
    }
  }
}
#tribe-events-pg-template {
  padding: 0 !important;
  .navwrap {
    margin-bottom: 0;
  }
}
.tribe-events-single {
  @include single-content-setup;
  .entry-title {
    @include scaled-spacer('margin','bottom','lg');
    h1 {
      @include scaled-spacer('margin','bottom','xs-sm');
    }
    time,address {
      display: block;
      line-height: 1.4;
      span {
        display: block;
      }
    }
  }
}
.single-tribe_events {
  #main-content {
    padding-bottom: 0;
  }
}
