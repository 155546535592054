// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-control {
  border: 1px solid rgba($brand-primary,.5);
  &::-webkit-input-placeholder {
	  color: $input-placeholder-color;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: $input-placeholder-color;
	}
	&::-moz-placeholder {  /* Firefox 19+ */
	  color: $input-placeholder-color;
	}
	&:-ms-input-placeholder {
	  color: $input-placeholder-color;
	}
	&:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
	&:focus-visible {
  	outline: 1px solid $brand-color;
	}
}
select.form-control {
  @include select-control;
}
label.small-label {
  font-size: $font-size-xs;
  text-transform: uppercase;
}
.gform_legacy_markup_wrapper,.gform_wrapper {
  .gsection {
    margin: 0;
    border: none;
  }
  h2.gsection_title, {
    @include headline;
    @include resize-header(h2);
    margin: 0;
  }
  h3.gform_title {
    @include headline;
    @include resize-header(h3);
    margin: 0;
  }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),select,textarea {
    @extend .form-control;
    width: 100% !important;
    margin-left: 0 !important;
    padding: $input-padding-y $input-padding-x !important;
    height: auto;
    border: 1px solid rgba($brand-primary,.5) !important;
  }
  textarea,textarea.large {
    height: 110px !important;
  }
  select {
    @include select-control;
  }
  ul li.gfield {
    margin-top: 20px !important;
  }
  .ginput_complex {
    .gfield_time_hour, .gfield_time_minute, .gfield_time_ampm {
      display: inherit;
      input,select {
        line-height: 1 !important;
        min-width: 78px;
        max-width: 80pxz;
      }
    }
    .gfield_time_hour {
      margin-right: 15px;
      i {
        margin-top: 25%;
      }
    }
  }
  .gfield_label {
    margin-bottom: 5px;
  }
  .top_label div.ginput_container {
    margin-top: 0 !important;
  }
  .field_description_below:not(.gfield_error) .gfield_description {
    padding-top: 4px !important;
  }
  li.gfield.gfield_error {
    background-color: transparent;
    border: none !important;
    padding: 0 !important;
    &.gfield_contains_required label.gfield_label {
      margin-top: 0 !important;
    }
  }
  .gform_footer input.button,
  .gform_footer input[type=submit],
  .gform_page_footer input.button,
  .gform_page_footer input[type=submit] {
    @extend .btn;
  }
/*
  .ginput_container_checkbox {
    .gfield_checkbox {
      margin: 0 !important;
      .gchoice {
        label {
          font-size: $font-size-base;
          &:before {
            content: "\f0c8";
            display: inline-block;
            font-family: "Font Awesome 5 Pro";
            font-size: $font-size-lg;
            margin-right: $grid-gutter-width/2;
          }
        }
        input[type="checkbox"] {
          display: none;
          &:checked+label {
            font-weight: $font-weight-base;
            &:before {
              content: "\f14a";
            }
          }
        }
      }
    }
  }
*/
}
