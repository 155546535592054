section.hero {
  @include color-block('black');
  min-height: 480px;
  position: relative;
  align-items: center;
  .home & {
    min-height: 565px;
    @include media-breakpoint-up(lg) {
      min-height: 640px;
    }
  }
  article {
    width: 100%;
    .details {
      @include hero-column-width;
      @include last-child-zero-margin;
      @include scaled-spacer('padding','top','lg');
      @include scaled-spacer('padding','bottom','lg');
      text-align: center;
      figure {
        @include scaled-spacer('margin','bottom','sm');
        margin-left: auto;
        margin-right: auto;
        width: $grid-gutter-width*4;
      }
      h1 {
        @include scaled-spacer('margin','bottom','sm');
        @include resize-header(hero);
      }
      p {
        @include scaled-spacer('margin','bottom','sm');
      }
      .ctas {
        @include list-normalize;
        li {
          @include scaled-spacer('margin','bottom','sm');
          @include scaled-spacer('margin','left','xs-sm');
          @include scaled-spacer('margin','right','xs-sm');
          display: inline-block;
        }
      }
    }
  }
  &:before {
    content: "";
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.35;
  }

}
