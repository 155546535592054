html {
  font-size: 16px;
  height: 100%;
  scroll-behavior: smooth;
  body {
    padding: $header-height-sm 0 0 0;
    @include media-breakpoint-up(lg) {
      padding: 0 $header-width 0 0
    }
  }
}
.site-container {
  .wrap {
    @include full-padding-spacer(sm);
    @include media-breakpoint-up(lg) {
      @include full-padding-spacer(md);
    }
  }
}
.content-wrap {
  @include color-block('gray');
  .blog &,.category &,.post-type-archive-tribe_events & {
    @include color-block('white');
  }
}
body:not(.post-type-archive-tribe_events) {
  .content-wrap {
    @include full-padding-spacer(xs);
    padding-top: 0;
    padding-bottom: 0;
  }
  &.blog,&.category {
    .content-wrap {
      padding: 0;
    }
  }
  &.single-tribe_events {
    article.hentry {
      div.entry-title {
        @include color-block('black');
      }
    }
  }
}
.container-fluid {
  &.primary-container {
    @include media-breakpoint-down(sm) {
      padding: 0 $grid-gutter-width;
    }
  }
}


img {
  @include img-fluid;
}
a {
  @include transition($link-transition);
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
}

/* FONTS
  --------------------------------------- */
.overline {
  @include overline;
  @include paragraph-spacing;
}
h1,.h1,h2,.h2,h3,.h3 {
  @include headline;
  @include scaled-spacer('margin','bottom','md');
}
h3,.h3 {
  @include scaled-spacer('margin','bottom','sm');
}
h4,.h4 {
  @include overline;
  @include resize-header(h4);
  @include scaled-spacer('margin','bottom','xs');
}
h5,.h5,h6,.h6 {
  @include overline;
  @include scaled-spacer('margin','bottom','xs');
}
p,ol,ul,blockquote,.wp-block-image {
  @include paragraph-spacing;
}


/* ELEMENTS
  --------------------------------------- */
.site-container {
  position: relative;
}


/* NAVS
  --------------------------------------- */
nav.posts-navigation {
  @include scaled-spacer('margin','top','sm');
  @include color-block('white');
  .single-post & {
    @include scaled-spacer('margin','top','md');
  }
  .nav-links {
    @include make-row();
    &>div {
      @include make-col-ready();
      @include make-col(6);
      &.nav-next {
        margin-left: auto;
        text-align: right;
      }
      a {
        @extend .btn;
      }
    }
  }
}


/* AGE GATE
  --------------------------------------- */
.age-gate-wrapper {
  @include color-block('black');
  .age-gate-heading {
    &.age-gate-logo {
      @include scaled-spacer('margin','bottom','md');
      img {
        width: 40%;
        height: auto;
      }
    }
  }
  button {
    @extend .btn;
  }
}


